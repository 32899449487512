
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

html {
  background-color: rgb(233, 228, 222);
}

#titlebar {
  padding: 10px;
  background-color: rgb(101, 68, 37);
  height: calc(7vh + 20px);
  display: flex;
  align-items: center;
  justify-content: center;
}
#title {
  font-family: "Noto Serif";
  font-size: calc(1.7vw + 3.5vh + 4px);
  color: white;
  margin: 0;
}

b{
  text-shadow: 3px 3px rgba(255, 255, 255, 0.18);
}

#game {
  width: 100vw;
  height: calc(42vh + 15vw);
  background-color: rgb(233, 228, 222);
  padding: 2em 0;
}

#chessboard {
  text-align: center;
  margin: auto;
  width: calc(42vh + 15vw);
  height: calc((42vh + 15vw) * (9/8));
  display: flex;
  flex-direction: column;
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; */
}

#chessboard .tileRow {
  display: flex;
  height: calc(100% / 9);
  width: 100%;
}

#chessboard .square{
  display: flex;
  align-items: center;
  height: 100%;
  width: 12.5%;
  font-size: 90px;
  margin: 0;
  text-align: center;
}

#chessboard .square img{
  display: block;
  height: 75%;
  margin: auto;
  /* padding-bottom: 4%; */
}

.white {
  background-color: rgb(255, 255, 230);
}

.black {
  background-color: rgb(100, 60, 23);
}

/* #chessboard .selected.white{ */
  /* background-color: rgb(255, 87, 75); */
  /* background-color: rgb(255, 132, 104); */
/* } */
#chessboard .selected {
  position: relative;
}

#chessboard .selected:after {
  content: "";
  display: block;
  position: absolute;
  top:1%;
  left:0;
  bottom: 0;
  right: 0;
  margin: 30%;
  height: 40%;
  width: 40%;
  background: rgba(43, 43, 43, 0.695);
  border-radius: 40%;
}

div #infoPanel{
  background-color: rgb(134, 85, 42);
  font-family: "Noto Serif";
  color: white;
  position: absolute;
  display: flex;
  top: 40vh;
  right: 2vw;
  /* transform: translate(-50%, -50%); */
  width: calc(23vw - 65px);
  height: calc(6vw + 15vh);
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
#infoPanel h1{
  font-size: calc(3.5vw - 18px + 2vh);
  margin: 0;
}
#infoPanel h2{
  font-size: calc((3.5vw - 18px + 2vh) * 0.7);
  margin: 0;
  margin-bottom: 1vh;
}

/* #chessboard .selected.black{ */
  /* background-color: rgb(189, 35, 0); */
  /* background-color: rgb(169, 48, 0); */
/* } */

.sideLabel {
  font-weight: 650;
  font-size: calc(0.8vw + 1.2vh);
}

#colLabel{
  width: 3.6%;
  margin-top: -2px;
  margin-bottom: auto;
  text-align: center;
  margin-left: 8.4%;
}

#rowLabel{
  width: 5%;
}

#overlay{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 40%;
  margin-top: 0;
  top: 0;
}

.startButton{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: 17%;
  font-family: "Noto Serif";
  font-size: 3em;
  font-weight: bold;
  background-color: rgba(134, 85, 42, 0.99);
  color: rgb(255, 255, 255);
}
.restartButton{
  position: absolute;
  top: 45vh;
  left: 2vw;
  /* transform: translate(-50%, -50%); */
  width: calc(20vw - 60px);
  height: calc(5vw + 10vh);
  font-family: "Noto Serif";
  font-size: calc(3.3vw - 25px + 2vh);
  font-weight: bold;
  background-color: rgb(134, 85, 42);
  color: rgb(255, 255, 255);
}
.startButton:hover, .restartButton:hover{
  background-color: rgb(114, 72, 36)
}

